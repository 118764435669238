import React, { useState, useEffect } from 'react';
import TableauEmbed from '../TableauEmbed';
import Header from '../Header/Header';
import ReactGA from 'react-ga';
//import { Test } from './Leaderboard.styles';

const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

const getHeight = () => window.innerHeight 
|| document.documentElement.clientHeight 
|| document.body.clientWidth;

const Leaderboard = (props) => {
  const [ width, setWidth ] = useState(getWidth());
  const [ height, setHeight ] = useState(getHeight());
  const [ vizOptions, setVizOptions ] = useState({
    hideTabs: true,
    hideToolbar: true,
    device: width <= 1050 ? 'phone' : 'desktop',
    width: width + 'px',
    height: height + 'px'
  });

  ReactGA.pageview('/leaderboard');

  function setOptions () {
    console.log('[Dashboard.js] setOptions');
    setWidth(getWidth());
    setHeight(getHeight());
    let vizWidth = getWidth();
    let vizHeight = getHeight();
    const showMobile = vizWidth <= 1050;
    if (showMobile) {
      vizHeight = 1600;
    }
    const options = {
      hideTabs: true,
      hideToolbar: true,
      device: showMobile ? 'phone' : 'desktop',
      width: vizWidth + 'px',
      height: vizHeight + 'px'
    }
    console.log('[Dashboard.js] options', options);
    setVizOptions(options);
  };
  
  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        setOptions();
      }, 300);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);
    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
    
  }, []);

  return (
  <div className="LeaderboardWrapper">
    <Header/>
    <div className="py-2">
    <main>
      <div className="mx-auto">
        <div className="">
          <TableauEmbed
            url="https://clientreporting.theinformationlab.co.uk/t/PublicDemo/views/TC20PinballScores/HighScores"
            options={vizOptions}
          />
        </div>
      </div>
    </main>
  </div>
  </div>
)};

export default Leaderboard;
