import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { Transition } from '@tailwindui/react';
import TC20Logo from '../../img/tc20-dark.png';
import BubblesLogo from '../../img/bubbles.svg';

const Header = (props) => {
  
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="HeaderWrapper">
      <div className="relative bg-white shadow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
              <div className="w-0 flex-1 flex">
                <div className="inline-flex">
                  <Link to="/">
                    <img className="h-8 w-auto sm:h-10" src={BubblesLogo} alt="The Information Lab" />
                  </Link>
                  <div className={(props.showScoreSubmit ? "" : "hidden ") + "rounded-md shadow ml-6"}>
                    <div role="button" tabIndex={0} onClick={props.toggleSubmitScore} className="w-full flex items-center justify-center py-1 px-4 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange transition duration-150 ease-in-out md:py-2 md:text-lg">
                      Submit Your Score
                    </div>
                  </div>
                </div>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <button type="button" onClick={() => setIsOpen(true)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
              <nav className="hidden md:flex space-x-10">
                <div role="button" tabIndex={0} onClick={props.toggleGameSelector} className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 transition ease-in-out duration-150 cursor-pointer">
                  Games
                </div>
                <Link to="/leaderboard" >
                  <div className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 transition ease-in-out duration-150">
                    Leaderboard
                  </div>
                </Link>
                <div className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 transition ease-in-out duration-150">
                  Contact
                </div>
                <div className="text-gray-500 inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 transition ease-in-out duration-150 cursor-pointer">
                  <a href="https://tc20.tableau.com">
                    <img src={TC20Logo} alt="TC20" style={{height: 30}}/>
                  </a>
                </div>
              </nav>
            </div>
          </div>

          <Transition
            show={isOpen}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div ref={ref} className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden">
                <div className="rounded-lg shadow-lg">
                  <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
                    <div className="pt-5 pb-6 px-5 space-y-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <Link to="/" onClick={() => setIsOpen(false)} >
                            <img className="h-8 w-auto" src={BubblesLogo} alt="The Information Lab" />
                          </Link>
                        </div>
                        <div className="-mr-2">
                          <button type="button" onClick={() => setIsOpen(false)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div>
                        <nav className="grid gap-y-8">
                          <div className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                            <svg className="flex-shrink-0 h-6 w-6 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <div role="button" tabIndex={0} onClick={props.toggleGameSelector} className="text-base leading-6 font-medium text-gray-900 cursor-pointer">
                              Games
                            </div>
                          </div>
                          <div className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                            <svg className="flex-shrink-0 h-6 w-6 text-orange-600"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                            </svg>
                            <Link to="/leaderboard" >
                              <div className="text-base leading-6 font-medium text-gray-900">
                                Leaderboard
                              </div>
                            </Link>
                          </div>
                          <div className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                            <svg className="flex-shrink-0 h-6 w-6 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                            </svg>
                            <div className="text-base leading-6 font-medium text-gray-900">
                              Contact
                            </div>
                          </div>
                          <div className="-m-3 p-3 px-0 pl-2 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150 cursor-pointer">
                            <a href="https://tc20.tableau.com" className="flex items-center space-x-3">
                              <img src={TC20Logo} alt="TC20" style={{height: 30}}/>
                              <div className="text-base leading-6 font-medium text-gray-900">
                                Tableau Conference 2020
                              </div>
                            </a>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Transition>
        </div>
    </div>
  )
};

export default Header;
